.counter-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  &__left{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 24px;
    background: linear-gradient(83.65deg, #0F0326 0%, #97A7B5 122.38%),
    linear-gradient(83.65deg, #0F0326 0%, #97A7B5 122.38%),
    linear-gradient(83.65deg, #0F0326 0%, #A1EBB2 122.38%);
    width: 90%;
    p{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $secondary;
      letter-spacing: 0.02em;
      margin: 0;
      span{
        margin: 8px 0 0;
        font-weight: 500;
        font-size: 40px;
        line-height: 47px;
        letter-spacing: 0.02em;
        display: block;
        color: #FCFCFC;
      }
    }
    mat-icon{
      width: 50px;
      height: 50px;
      color: #FCFCFC;
      font-size: 50px;
    }
  }
  &__right{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #4d4d67;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #FCFCFC;
    transition: all 0.5s ease ;
    mat-icon{
      font-size: 35px;
      width: 35px;
      height: 35px;
    }
    &:hover{
      color: #FCFCFC;
      filter: invert(70%);
    }
  }
}