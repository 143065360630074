app-footer {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 15px;
  width: 100%;
  background: $primary;
  margin-top: auto;

  p {
    margin: 0!important;
    text-align: center;
    color: #e2e2e2;
  }
}

.mat-sidenav-container {
  app-footer {
    position: relative;
  }
}