.form-container {
  margin-top: -50px;
  padding: 32px !important;
}

.form-content{
  max-width: 1590px;
  margin: 0 auto;

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $primary;
  }

  .shadow {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
      0px 4px 20px -2px rgba(50, 50, 71, 0.08) !important;
  }

  .image-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: $secondary;
  }

  .checkboxes{
    margin: 15px 0;
  }
}

.file-upload{
  &__label{
    box-sizing: border-box;
    padding: 20px;
    border: 1px dashed $secondary;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    min-height: 262px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: $secondary;
    
    strong {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      letter-spacing: 0.02em;
      color: #0F0326;
    }

    .or {
      margin: 10px 0;
    }

    .btn{
      padding: 16px 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      letter-spacing: 0.02em;
      color: $light;
    }

    .hidden {
      display: none!important;
    }

    .cropped-image {
      min-height: 200px;
      max-height: 200px;
    }
  }

  #image-upload {
    display: none;
  }
}

h2.form-title{
  display: block;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #0F0326;
}

.editor {
  border-radius: 5px;
}

.row-image {
  textarea{
    max-height: 227px;
    min-height: 227px;
    overflow-y: scroll;
    resize: none;
  } 
}
.ck-editor{
  &__editable{
    min-height: 262px;
    max-height: 262px;
    overflow-y: scroll;
  }
}
.image-cropped {
  margin: 20px 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  img {
    display: block;
    max-width: 70%;
    margin: 0 auto;
    height: auto;
  }
}