.header {
  padding: 26px 32px;
  background: #F5F7F7;

  app-header {
    .mat-icon-button {
      width: unset;
    }

    .widget-icon {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }

    .profile button{
      background-color: #F5F7F7;
    }

    .username {
      padding: 0 40px;
      font-weight: 500;
      font-size: 16px;
      color: $secondary;
    }

    .logout {
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      color: $primary;
      text-decoration: none;
      mat-icon{
        margin-left: 15px;
      }
    }
  }
}