tr.mat-row,
tr.mat-footer-row {
  height: unset !important;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 15px 5px !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}

tr.mat-header-row {
  height: unset !important;

}

.mat-header-cell {
  font-size: 15px;
}

.table>thead {
  vertical-align: middle;
}

.table tr {
  vertical-align: middle;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 24px !important;
  min-width: 110px;
  max-width: 110px;
  width:110px;
}

th.mat-header-cell:nth-last-child(2),
td.mat-cell:nth-last-child(2),
td.mat-footer-cell:nth-last-child(2) {
  min-width: 150px;
  max-width: 150px;
  width:150px;
}