.mat-drawer-container {
  margin-bottom: 40px;
  height: 100%;

  h1 {
    padding: 32px 32px;
    font-weight: 700;
    font-size: 30px;
    color: $secondary;
  }
}