

.sidenav-fixed {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  bottom: 0 !important;
  width: 100%;
  max-width: 250px;
  background: $dark;
  .mat-list-item {
    height: 63px !important;
  }
  hr {
    background-color: #FFFFFF;
  }
  .menu-list {

    .mat-icon {
      color: $secondary;
    }

    .label {
      margin-left: 27px;
      font-size: 16px;
      color: #E1E6EA;

    }
  }

  .logo {
    background-color: white;
    border-radius: 50%;
    border: 2px solid #FFF;
  }

  .active-list-item {
    .mat-icon {
      color: $primary!important;
    }
  }
}

.sidebar-container {
  background-color: $light;
}

.content-container {
  padding-bottom: 40px;
}