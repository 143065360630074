.bg-login {
  width: 100%;
  height: 100%;
  background: url(../../../img/login-bg.jpg)no-repeat center center fixed;
  background-size: cover;
}

.login {
  position: fixed;
  max-width: 600px;
  padding: 40px 30px;
  width: 90%;
  margin: auto auto;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;

  form {
    width: 100%;
  }

  &-icon {
    width: unset !important;
    height: unset !important;
    font-size: 50px;
    margin-left: 10px;
  }

  &-field {
    width: 100%;
  }

  &-btn {
    padding: 5px 10px !important;
  }
}