html,
body {
  display: block;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.brand {
  p {
    &.logo {
      svg {
        width: 54px;
        height: 54px;
      }
    }

    &.text {
      color: #97A7B5;
      display: flex;
      flex-direction: row;
      font-family: "Mulish",
        sans-serif;
      font-weight: 700;
      margin-left: 0.8rem;

      .color {
        color: #f66847;
        margin: 0 0.3rem
      }
    }
  }
}

.button-dark {
  background-color: $dark;
  color: $light;
}

.chart-counter {
  .chart-color {
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
  }

  p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    margin: 16px 0;
  }
  &__description{
    table {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      tr {
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 16px 0;
        border-bottom: 1px solid #E1E6EA;

        td {
          text-align: center;
          width: 40px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.02em;
        }

        td:first-of-type {
          width: 100%;
          text-align: left;
          font-style: normal;
          font-weight: 400;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
.page-container{
  padding: 32px !important;
}